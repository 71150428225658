"use client";
import { useEffect, useState } from "react";
import clsx from "clsx";

export const AutoCarousel = ({ testimonials }) => {
  const [activeItem, setActiveItem] = useState(0);

  const handleButtonClick = (index) => {
    setActiveItem(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveItem((prevItem) => (prevItem + 1) % testimonials.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, [testimonials.length]);

  return (
    <div className="flex justify-center">
      <div>
        <div className="inline-grid place-items-center">
          {testimonials.map((item, index) => (
            <div
              key={index}
              className={clsx(
                "col-start-1 row-start-1 w-full transition-opacity duration-500 ease-in-out",
                index === activeItem ? "opacity-100" : "opacity-0",
              )}
            >
              <div className="card w-full bg-base-100">
                <div className="card-body">
                  <p className="italic">{item.q}</p>
                  <h2 className="card-title font-light text-secondary">
                    — {item.a}
                  </h2>
                </div>
              </div>
              ,
            </div>
          ))}
        </div>
        <div className="flex w-full justify-center gap-2 py-2">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => handleButtonClick(index)}
              className={clsx("btn btn-circle btn-info btn-xs", {
                "btn-outline": activeItem !== index,
              })}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};
